<template>
  <footer>
    <nav>
      <img
        src="../../assets/logo.png"
        width="40"
        height="40"
        alt="The website logo which is a curved letter W with a snake head on the upper left"
        @click="navigateToHomePage()"
      >
      <ul>
        <li>
          <a href="mailto:contact@snapvocab.com?subject=Contact">
            Contact Us
          </a>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  methods: {
    navigateToHomePage() {
      this.$router.push("/home");
    },
  },
};
</script>

<style scoped>
footer {
  background-color: black;
  padding: 50px 0 20px;
}

nav {
  width: 90%;
  max-width: 60rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

img {
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

a {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #1db954;
}
</style>