<template>
  <section class="demo">
    This is a demo mode.
    <br>
    Please <a @click="navigateToSignUpPage()">sign up</a> for an account to save your library.
  </section>
</template>

<script>
export default {
  methods: {
    navigateToSignUpPage() {
      this.$router.push('/sign-up');
    }
  }
};
</script>

<style scoped>
.demo {
  text-align: center;
  padding: 1rem;
  margin: 1rem auto;
  background: red;
  border: thick double;
}

a {
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
</style>
