<template>
  <section>
    <div class="container">
      <h1>The vocabulary tool you will enjoy using</h1>
      <h2>
        Snapvocab helps Chinese and Japanese students learning characters that are most useful to them.
      </h2>
      <div class="actions">
        <button @click="navigateToAddWordPage()">
          Try It Now
        </button>
      </div>
      <br>
      <div>
        <a @click="navigateToHowItWorksPage()">How it works</a>
      </div>
    </div>
  </section>
</template>

<script>
import closeSidePanel from "../util/side-panel-utils.js";

export default {
  beforeMount() {
    closeSidePanel();
  },
  mounted() {
    this.$gtag.event("page_view", {
      page_path: "/home",
    });
  },
  methods: {
    navigateToHowItWorksPage() {
      this.$router.push("/how-it-works");
    },
    navigateToAddWordPage() {
      this.$router.push("/add-word");
    },
  },
};
</script>

<style scoped>
section {
  text-align: center;
  padding: 0.5rem;
  margin: 1rem auto;
  background: #fff;
  height: 100vh;
}

.container {
  margin-top: 20%;
  margin-left: 5%;
  margin-right: 5%;
}

h1 {
  color: #1db954;
  font-size: 1.9rem;
  font-weight: 900;
  line-height: 2.2rem;
}

h2 {
  font-size: 1rem;
}

.actions {
  margin-top: 2rem;
}

button {
  background-color: #1db954;
  border: 0px solid transparent;
  border-color: #116e32;
  border-radius: 500px;
  color: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 1.1rem 2rem;
  text-transform: uppercase;
}

a {
  font-size: 0.9rem;
  color: #1db954;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  section {
    min-height: 45rem;
  }

  .container {
    margin-left: 15%;
    margin-right: 15%;
  }

  h1 {
    font-size: 3.6rem;
    line-height: 3.9rem;
  }

  h2 {
    font-size: 2rem;
  }

  .actions {
    margin-top: 4rem;
  }

  button,
  a {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    margin-top: 10%;
  }

  h1 {
    font-size: 3.6rem;
    line-height: 3.9rem;
  }

  h2 {
    font-size: 2rem;
  }

  .actions {
    margin-top: 4rem;
  }

  button,
  a {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    margin-top: 15%;
  }

  h1 {
    font-size: 4.4rem;
    line-height: 4.7rem;
  }
}
</style>