const MAX_WORDS_RETURNED = 10;
const USEFUL_WORD_MIN_FREQUENCY = 3;
const NEW_WORD_INITIAL_FREQUENCY = 1;
const FREQUENCY_INCREMENT = 1;

class WordsClient {
  constructor() {
    // "interface marker"
  }
  
  // Methods signatures to be implemented by "subclasses"
  // findUsefulWords: () => words: [object]
  // delete: (word: string) => void
  // save: (words: [string]) => void
}

// Could be replaced by marshall util from SDK 
// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/modules/_aws_sdk_util_dynamodb.html#marshall-1
function createNewWordItemWith(userId, word) {
  return {
    pk: { S: userId },
    sk: { S: word },
    frequency: { N: '' + NEW_WORD_INITIAL_FREQUENCY },
    frequencyword: { S: '' + NEW_WORD_INITIAL_FREQUENCY + word},
  };
}

function incrementWordFrequency(word) {
  word.frequency.N = '' + (parseInt(word.frequency.N) + FREQUENCY_INCREMENT);
  word.frequencyword.S = word.frequency.N + word.sk.S;
  return word;
}

export {
  MAX_WORDS_RETURNED, 
  USEFUL_WORD_MIN_FREQUENCY, 
  createNewWordItemWith,
  incrementWordFrequency,
  WordsClient};