import { SnapvocabStatefulStack } from '../../cdk-exports.json';
import { PutItemCommand } from "@aws-sdk/client-dynamodb";
import { getDynamoClientForUnauthenticatedUser, getDynamoClientForAuthenticatedUser } from "./dynamo-client.js";

export default class CustomersApiClient {
  constructor(user) {
    this._user = user;
  }

  async save(userSubOrEmail) {
    if(typeof this._user === 'undefined') {
      const dynamoClient = getDynamoClientForUnauthenticatedUser();
      this._persist(dynamoClient, userSubOrEmail);
    } else {
      this._user.getSession( (err, userSession) => {
        if (err) {
          throw Error(err);
        } else {
          const dynamoClient = getDynamoClientForAuthenticatedUser(userSession.getIdToken().getJwtToken());
          this._persist(dynamoClient, userSubOrEmail);
        }
      });
    }
  }
  
  async _persist(dynamoClient, userSubOrEmail) {
    const params = {
      TableName: SnapvocabStatefulStack.customerstablename,
      Item: {
        pk: { S: userSubOrEmail },
      },
    };
    
    const command = new PutItemCommand(params);
    dynamoClient.send(command); 
  }
}
