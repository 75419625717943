import { WordsClient, MAX_WORDS_RETURNED, USEFUL_WORD_MIN_FREQUENCY, createNewWordItemWith, incrementWordFrequency } from './words-client.js'

const UNFOUND_INDEX = -1;
const DEMO_USER_ID = "sub-user-demo";

export default class InMemoryWordsClient extends WordsClient {
  constructor() {
    super();
    this._words = [
      {
        pk: { S: DEMO_USER_ID},
        sk: { S: "栃" },
        frequency: { N: 6 },
        frequencyword: { S: "6栃"},
      },
      {
        pk: { S: DEMO_USER_ID},
        sk: { S: "謝謝" },
        frequency: { N: 5 },
        frequencyword: { S: "5謝謝" },
      },
      {
        pk: { S: DEMO_USER_ID},
        sk: { S: "颪" },
        frequency: { N: 8 },
        frequencyword: { S: "8颪" },
      },
      {
        pk: { S: DEMO_USER_ID},
        sk: { S: "美國" },
        frequency: { N: 6 },
        frequencyword: { S: "6美國" },
      },
    ];
  }

  async findUsefulWords() {
    let count = 0;

    return this._words
      .filter(
        (word) => word.frequency.N >= USEFUL_WORD_MIN_FREQUENCY
        && count++ < MAX_WORDS_RETURNED
      ).sort((firstElement, secondElement) => { 
        if(firstElement.frequency.N > secondElement.frequency.N) {
          return -1;
        } 
        if(firstElement.frequency.N < secondElement.frequency.N) {
          return 1;
        } 
        return 0;
      });
  }

  async delete(word) {
    this._words = this._words.filter(
      (wordItem) => wordItem.sk.S !== word
    );
  }

  async save(words) {
    const wordsAsLowerCase = words.map(element => element.toLowerCase());
    wordsAsLowerCase.forEach(word => {
      const existingWordIndex = this._findIndexOf(word);
      if (existingWordIndex === UNFOUND_INDEX) {
        this._addNewWord(word);
      } else {
        this._incrementExistingWordFrequency(existingWordIndex);
      }
    });
  }

  _findIndexOf(word) {
    return this._words.findIndex((wordItem) => wordItem.sk.S === word);
  }

  _addNewWord(word) {
    this._words.push(createNewWordItemWith(DEMO_USER_ID, word));
  }

  _incrementExistingWordFrequency(existingWordIndex) {
    this._words[existingWordIndex] = incrementWordFrequency(this._words[existingWordIndex]);
  }
}
