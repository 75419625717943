<template>
  <base-dialog
    :show="showAccountClosedPopup"
    title="Thank You"
    @close="closeAccountClosedPopup"
  >
    <p>Your account will be closed soon. You can still use it until the end of your trial period or current billing cycle.</p>
    <p>If you have any questions, please contact us at contact@snapvocab.com</p>
  </base-dialog>
  <base-container>
    <template #title>
      Account Overview
    </template>
    <template #default>
      <div v-if="userAuthenticated">
        <p class="profile">
          <span class="key">Username:</span> {{ user.attributes.email }}
        </p>
        <p>
          <button @click="closeAccount">
            Close account
          </button>
        </p>
      </div>
    </template>
  </base-container>
</template>

<script>
import closeSidePanel from "../util/side-panel-utils.js";

export default {
  data() {
    return {
      showAccountClosedPopup: false,
    };
  },
  computed: {
    userAuthenticated() {
      return this.$store.getters.userAuthenticated;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  beforeMount() {
    closeSidePanel();
  },
  mounted() {
    this.$gtag.event("page_view", {
      page_path: "/account",
    });
  },
  methods: {
    closeAccount() {
      this.$store.getters.customersClient
        .save(this.user.username)
        .then(() => {
          this.showAccountClosedPopup = true;
        })
        .catch((error) => {
          console.log(error.message || 'Close account failure');
        }
      );
    },
    closeAccountClosedPopup() {
      this.showAccountClosedPopup = false;
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 38rem;
}

@media all and (min-width: 414px) {
  .container {
    min-height: 33.5rem;
  }
} 

@media all and (min-width: 768px) {
  .container {
    min-height: 51.5rem;
  }
} 

@media all and (min-width: 1024px) {
  .container {
    min-height: 72.5rem;
  }
}

.profile {
  padding: 1rem;
}

.profile .key {
  color: #757575;
}

button {
  background-color: #d71e1e;
  border: 0px solid transparent;
  border-color: #116e32;
  border-radius: 500px;
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 0.6rem 2rem;
  text-transform: uppercase;
}
</style>