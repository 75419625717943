import { createApp } from 'vue'

import router from './router.js'
import store from './store/index.js';
import App from './App.vue'
import BaseContainer from './components/ui/BaseContainer.vue'
import BaseDialog from './components/ui/BaseDialog.vue'
import VueGtag from "vue-gtag-next";
import './configureAmplify';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID
  }
});
app.component('BaseContainer', BaseContainer);
app.component('BaseDialog', BaseDialog);

app.mount('#app');
