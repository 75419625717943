import { createRouter, createWebHistory} from 'vue-router'

import Home from "./pages/Home.vue";
import MyLibrary from "./pages/MyLibrary.vue";
import AddWord from "./pages/AddWord.vue";
import HowItWorks from "./pages/HowItWorks.vue";
import SignUp from "./pages/SignUp.vue";
import LogIn from "./pages/LogIn.vue";
import Account from "./pages/Account.vue";
import ImportWords from "./pages/ImportWords.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/home'},
    { path: '/home', component: Home},
    { path: '/how-it-works', component: HowItWorks},
    { path: '/my-library', component: MyLibrary},
    { path: '/add-word', component: AddWord},
    { path: '/import-words', component: ImportWords},
    { path: '/sign-up', component: SignUp},
    { path: '/log-in', component: LogIn},
    { path: '/account', component: Account},
    { path: '/:catchAll(.*)', redirect: '/home'}
  ]
})

export default router;
