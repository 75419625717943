<template>
  <div class="section">
    <base-dialog
      :show="!userAuthenticated && !helpInstructionsHasBeenShowed"
      title="Help: Add Word"
      @close="closeHelpPopup"
    >
      <p>
        You can add Chinese characters / Japanese Kanji to your library in two ways. The first is manual. Just
        type the character you want. While the second allows you to choose the
        desired character from a text.
      </p>
    </base-dialog>

    <TheDemoBanner v-if="!userAuthenticated" />
    <base-container>
      <template #title>
        Add Word
      </template>
      <template #default>
        <form @submit.prevent="submitData">
          <p>
            <span>
              <input
                id="userWordInputValue"
                v-model.trim="enteredWordValue"
                name="userWordInputValue"
                type="text"
                placeholder="Type characters here"
                required
              >
            </span>
            <span>
              <button>Add</button>
            </span>
          </p>
          <p>
            <textarea
              id="userTextArea"
              placeholder="Or paste a news article, a song lyrics or any text here. Then choose - double click or select - the character(s) you want and press the ADD button."
              rows="10"
            />
          </p>
        </form>
      </template>
    </base-container>
  </div>
</template>

<script>
import TheDemoBanner from "../components/TheDemoBanner.vue";
import closeSidePanel from "../util/side-panel-utils.js";

const EMPTY_TEXT = "";

export default {
  components: {
    TheDemoBanner,
  },
  data() {
    return {
      enteredWordValue: EMPTY_TEXT,
      helpInstructionsHasBeenShowed: false,
    };
  },
  computed: {
    userAuthenticated() {
      return this.$store.getters.userAuthenticated;
    },
  },
  beforeMount() {
    closeSidePanel();
  },
  mounted() {
    this.$gtag.event("page_view", {
      page_path: "/add-word",
    });

    document.onselectionchange = () => {
      let currentlySelectedText = document.getSelection().toString().trim();
      if (!(currentlySelectedText === EMPTY_TEXT)) {
        this.enteredWordValue = currentlySelectedText;
      }
    };
  },
  methods: {
    submitData() {
      this.$store.getters.wordsClient.save([this.enteredWordValue]);
      this.resetForm();  
    },
    resetForm() {
      this.enteredWordValue = EMPTY_TEXT;
    },
    closeHelpPopup() {
      this.helpInstructionsHasBeenShowed = true;
    },
  },
};
</script>

<style scoped>
.section {
  height: 100vh;
}

input {
  padding: 0.4rem 0rem 0.4rem 0.4rem;
  font-size: 1rem;
  border: 1px solid #d9dadc;
  background-size: 20px;
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

input:focus:valid {
  background-image: url("../assets/icons8-checkmark-64.png");
  outline: 2px solid #1ed760;
}

input:focus:invalid {
  background-image: url("../assets/icons8-delete-64.png");
  outline: 2px solid red;
}

button {
  background-color: #1ed760;
  border: 0px solid transparent;
  border-color: #116e32;
  border-radius: 500px;
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 0.6rem 2rem;
  text-transform: uppercase;
}

span {
  margin: auto 0.1rem;
}

textarea {
  border: 1px solid #d9dadc;
  font-size: 1rem;
  padding: 0.4rem 0rem 0.4rem 0.4rem;
  resize: none;
  width: 100%;
}
</style>