<template>
  <section>
    <h1 class="title">
      Why Snapvocab?
    </h1>
    <div class="blockquote-wrapper">
      <div class="blockquote">
        <h1>
          Without grammar, very little can be conveyed, without vocabulary,
          nothing can be conveyed.
        </h1>
        <h4>
          - Wilkins, David A.<br><em>Linguistics in language teaching</em>
        </h4>
      </div>
    </div>

    <p>
      In any language, vocabulary improves all areas of communication.
      Developing a reading habit is probably the best way to expand it.
    </p>
    <p>
      Research shows that Chinese and Japanese learners can acquire new vocabulary through reading if
      the overall text can be understood. In other words, if you know
      approximately 98% of the characters / kanji /  漢字 in a text, you can comfortably “pick up”
      the 2% that is unfamiliar. So, how to get there?
    </p>

    <h2 class="subtitle">
      Not all characters are equally valuable
    </h2>
    <p>
      You can create a list of characters but knowing which characters to add is a skill.
      If you are just adding every single unknown character you hear or read, that is
      eventually going to add up to a giant pile of character that is going to end
      up not very effective.
    </p>
    <p>
      An alternative would be to use character lists compiled by other learners. They
      are easy to find online but how do you know if they are suitable for you
      or if they are of good quality?
    </p>
    
    <h2 class="subtitle">
      Use the rule of three
    </h2>
    <p>
      <strong>Only learn something if you encounter it three times!</strong>
      This way, you are sure to learn a really useful character that belongs to your
      areas of interest. You will be more likely to learn a new character if it was
      used several times in a context you find interesting or are passionate
      about.
    </p>
    <p>
      Snapvocab helps you achieve that goal! Start adding unknown characters
      <a @click="navigateToAddWordPage()">here</a> from a news article or a song
      lyrics for example. For Chinese learners, you can also import 
      <a @click="navigateToImportWordsPage()">here</a> your favorite words from 
      <a href="https://www.pleco.com">Pleco©</a>. Then, check the useful characters in your 
      <a @click="navigateToMyLibraryPage()">library</a> that you should learn. 
      Easy right?
    </p>

    <!-- 
    <div class="content-in-one-column">
      <h2 class="subtitle">
        Snapvocab follows you everywhere
      </h2>
      <p>
        Enjoy Snapvocab wherever you are, whenever you want. It works on
        desktop, tablet, and mobile. Your characters library is
        seamlessly synchronized across all your devices.
      </p>
      <p class="text-center-aligned">
        <img
          alt="A screenshot of Snapvocab iOS application"
          class="responsive"
          src="../assets/snapvocab_ios.png"
        >
      </p>
    </div>

    <div class="content-in-two-columns">
      <div class="row">
        <div class="column">
          <div class="left-column">
            <h2 class="subtitle">
              Snapvocab follows you everywhere
            </h2>
            <p>
              Enjoy Snapvocab wherever you are, whenever you want. It works on
              desktop, tablet, and mobile. Your characters library
              is seamlessly synchronized across all your devices.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="text-center-aligned">
            <img
              alt="A screenshot of Snapvocab iOS application"
              class="responsive"
              src="../assets/snapvocab_ios.png"
            >
          </div>
        </div>
      </div>
    </div>
    -->

    <div class="text-center-aligned">
      <div>
        What are you waiting for?
        <div class="signup-button">
          <button @click="navigateToAddWordPage()">
            Try It Now
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.$gtag.event("page_view", {
      page_path: "/how-it-works",
    });
  },
  methods: {
    navigateToAddWordPage() {
      this.$router.push("/add-word");
    },
    navigateToImportWordsPage() {
      this.$router.push("/import-words");
    },
    navigateToMyLibraryPage() {
      this.$router.push("/my-library");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

section {
  text-align: justify;
  padding: 0.5rem;
  margin: 1rem auto;
  background: #fff;
}

.title {
  color: #1db954;
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.subtitle {
  color: #1db954;
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
}

.text-center-aligned {
  text-align: center;
}

.responsive {
  max-width: 200px;
  width: 100%;
  height: auto;
}

button {
  background-color: #1db954;
  border: 0px solid transparent;
  border-color: #116e32;
  border-radius: 500px;
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
}

a {
  color: #1db954;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.content-in-two-columns {
  display: none;
}

/* Quote CSS */
/* center the blockquote in the page */
.blockquote-wrapper {
  display: flex;
}

/* Blockquote main style */
.blockquote {
  position: relative;
  max-width: 50rem;
  margin: 0rem auto 1rem auto;
  align-self: center;
}

/* Blockquote header */
.blockquote h1 {
  font-family: "Abril Fatface", cursive;
  position: relative; /* for pseudos */
  color: black;
  font-size: 1.46rem;
  line-height: 1.2;
  font-weight: normal;
  margin: 0;
  border: solid 2px;
  border-radius: 20px;
  padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h1:after {
  content: "";
  position: absolute;
  border: 2px solid black;
  border-radius: 0 50px 0 0;
  width: 60px;
  height: 60px;
  bottom: -62px;
  left: 50px;
  border-bottom: none;
  border-left: none;
  z-index: 3;
}

.blockquote h1:before {
  content: "";
  position: absolute;
  width: 80px;
  border: 6px solid white;
  bottom: -3px;
  left: 50px;
  z-index: 2;
}

/* Blockquote subheader */
.blockquote h4 {
  position: relative;
  color: black;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  padding-top: 15px;
  z-index: 1;
  margin-left: 150px;
  padding-left: 12px;
  text-align: left;
}

.blockquote h4:first-letter {
  margin-left: -12px;
}

@media all and (min-width: 768px) {
  .title {
    font-size: 2.5rem;
  }

  .blockquote h1 {
    font-size: 2.2rem;
  }

  .subtitle {
    font-size: 2rem;
  }

  .signup-button {
    display: inline;
  }
}

@media all and (min-width: 1024px) {
  .title {
    font-size: 3rem;
  }

  .blockquote h1 {
    font-size: 2.7rem;
  }

  .subtitle {
    font-size: 2.5rem;
  }

  .content-in-one-column {
    display: none;
  }

  .content-in-two-columns {
    display: block;
    margin-top: 4%;
    margin-bottom: 4%;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .column {
    float: left;
    width: 50%;
  }

  .left-column {
    margin-top: 20%;
    margin-left: 5%;
  }
}
</style>