import Amplify from 'aws-amplify';
import { SnapvocabStatefulStack } from '../cdk-exports.json';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const CDKConfig = {
  aws_project_region: SnapvocabStatefulStack.awsprojectregion,
  aws_cognito_region: SnapvocabStatefulStack.awscognitoregion,
  aws_user_pools_id: SnapvocabStatefulStack.awsuserpoolsid,
  aws_user_pools_web_client_id: SnapvocabStatefulStack.awsuserpoolswebclientid,
  aws_cognito_identity_pool_id: SnapvocabStatefulStack.aws_cognito_identity_pool_id,
  aws_mandatory_sign_in: SnapvocabStatefulStack.awsmandatorysignin,
  oauth: SnapvocabStatefulStack.oauth,
};

Amplify.configure(CDKConfig);