<template>
  <section>
    <base-dialog
      :show="isSignedUp"
      title="Thank You"
      @close="confirmSignedUp"
    >
      <p>You will soon receive an email from <strong>contact@snapvocab.com</strong> with a link to our partner Stripe to start your free trial period. Happy learning!</p>
    </base-dialog>
    <form @submit.prevent="submitData">
      <h1>Try It Free for 1 month</h1>
      <h2>No commitment. Cancel anytime.</h2>
      <div class="plan-container">
        <p class="discount-container">
          1 month free
        </p>
        <h3>Monthly Billing</h3>
        <p class="pricing-container">
          $4.99 USD/month after offer period
        </p>
        <p class="large-container">
          <input
            id="email"
            v-model.trim="enteredEmail"
            name="email"
            placeholder="Your Email address"
            required
            type="email"
          >
        </p>
        <p class="hidden">
          <input
            id="name"
            v-model="enteredName"
            name="name"
          >
          <input
            id="csrf-token"
            v-model="csrfToken"
            name="csrf-token"
          >
        </p>

        <p class="large-container action-container">
          <button>Get Started</button>
        </p>
      </div>
    </form>
  </section>
</template>

<script>
import closeSidePanel from "../util/side-panel-utils.js";

const EMPTY_TEXT = "";
const CURRENT_YEAR = new Date().getFullYear();

export default {
  data() {
    return {
      enteredEmail: EMPTY_TEXT,
      enteredName: EMPTY_TEXT,
      csrfToken: EMPTY_TEXT,
      isSignedUp: false,
    };
  },
  beforeMount() {
    closeSidePanel();
  },
  mounted() {
    this.$gtag.event("page_view", {
      page_path: "/sign-up",
    });

    this.initCsrfToken();
  },
  methods: {
    submitData() {
      if (this.isDataValid()) {
            
        this.$gtag.event("sign_up", {
          method: "email",
        });
    
        this.$store.getters.customersClient
          .save(this.enteredEmail)
          .then(() => {
            this.isSignedUp = true;
          })
          .catch((error) => {
            console.log(error.message || 'Add email to customers table failure');
          }
        );
      }

      this.resetForm();
    },
    isDataValid() {
      return this.enteredName === EMPTY_TEXT && this.csrfToken == CURRENT_YEAR;
    },
    resetForm() {
      this.enteredEmail = EMPTY_TEXT;
    },
    confirmSignedUp() {
      this.isSignedUp = false;
    },
    initCsrfToken() {
      this.csrfToken = CURRENT_YEAR;
    },
  },
};
</script>


<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

section {
  text-align: justify;
  padding: 0.5rem;
  margin: 1rem auto;
  background: #fff;
  height: 100vh;
}

h1 {
  font-size: 2rem;
  letter-spacing: -0.08rem;
  text-align: center;
  margin-top: 0.625rem;
  margin-bottom: 0rem;
}

h2 {
  font-size: 1rem;
  text-align: center;
}

.plan-container {
  border: solid 0.125rem #acacac;
  border-radius: 0.625rem;
  box-shadow: rgb(0 0 0 / 30%) 0rem 0rem 0rem 0.125rem inset;
  padding-top: 0.5rem;
  padding-left: 0.938rem;
  margin-top: 3.125rem;
}

.discount-container {
  background-color: rgb(46, 119, 208);
  border-radius: 0.25rem;
  color: rgb(255, 255, 255);
  font-size: 0.875rem;
  font-weight: 900;
  padding: 0.25rem 0.5rem;
  width: 6.438rem;
}

h3 {
  margin: 0rem;
}

.pricing-container {
  margin-top: 0.313rem;
  margin-bottom: 0.625rem;
}

.large-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 25rem;
  width: auto;
  padding-left: 0rem;
  padding-right: 1.25rem;
}

.action-container {
  margin-bottom: 1.3rem;
}

input {
  text-align: center;
  width: 100%;
  font-size: 1rem;
  line-height: 2;
}

button {
  width: 100%;
  background-color: #1db954;
  border: 0rem solid transparent;
  border-color: #116e32;
  border-radius: 31.25rem;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1rem;
  padding: 1.1rem 2rem;
  text-transform: uppercase;
}

.hidden {
  display: none;
}

@media all and (min-width: 768px) {
  .plan-container {
    margin-left: 9rem;
    margin-right: 9rem;
  }
}

@media all and (min-width: 1024px) {
  .plan-container {
    margin-left: 16rem;
    margin-right: 16rem;
  }
}
</style>