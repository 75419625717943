export default {
  initStateForDemoUser(state, params) {
    state.userSignedIn = false;
    state.user = null;
    state.wordsClient = params.wordsClient;
    state.customersClient = params.customersClient;
  },

  initStateForSignedInUser(state, params) {
    state.userSignedIn = true;
    state.user = params.cognitoUser;
    state.wordsClient = params.wordsClient;
    state.customersClient = params.customersClient;
  },
};