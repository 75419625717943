<template>
  <section class="container">
    <h1>
      <slot name="title" />
    </h1>
    <div class="content">
      <slot />
    </div>
  </section>
</template>

<style scoped>
.container {
  text-align: center;
  padding: 0.5rem;
  margin: 1rem auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: #fff;
}

h1 {
  color: #1db954;
  margin: 0.25rem;
  border-bottom: 1px solid #efefef;
}

.content {
  margin: 1rem auto;
}
</style>