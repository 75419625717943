export default {
  userAuthenticated(state) {
    return state.userSignedIn && state.user !== null;
  },
  wordsClient(state) {
    return state.wordsClient;
  },
  customersClient(state) {
    return state.customersClient;
  },
  user(state) {
    return state.user;
  }
};
