<template>
  <section>
    <!-- eslint-disable vue/no-deprecated-slot-attribute -->
    <!-- eslint-disable vue/attribute-hyphenation -->
    <amplify-authenticator username-alias="email">
      <amplify-sign-in 
        slot="sign-in"
        header-text="Log in to your account"
        username-alias="email" 
        hide-sign-up="true"
        submit-button-text="Log In"
      />
    </amplify-authenticator>
    <!-- eslint-enable -->   
  </section>
</template>

<script>
import closeSidePanel from "../util/side-panel-utils.js";
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { AuthState } from "@aws-amplify/ui-components";

export default {
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined
    }
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      
      if (authState === AuthState.SignedIn) {
        this.$store.dispatch('userHasSignedIn', this.user);
        this.$router.push('/my-library');
      }
    })
  },
  beforeMount() {
    closeSidePanel();
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
  mounted() {
    this.$gtag.event("page_view", {
      page_path: "/log-in",
    });
  },
};
</script>


<style scoped>

section {
  text-align: justify;
  padding: 0.5rem;
  margin: 1rem auto;
  background: #fff;
  height:100vh;
}

amplify-authenticator {
  --margin-bottom: 25rem;
  --box-shadow: none;
  --amplify-primary-color: #1db954;
  --amplify-primary-shade: #1db954;
  --amplify-primary-tint: #1db954;
}
</style>