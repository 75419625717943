import { createStore} from 'vuex'
import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

const store = createStore({
  state() {
    return {
      userSignedIn: false,
      user: null,
      wordsClient: null,
      customersClient: null,
    }
  },
  getters,
  mutations,
  actions
});

export default store;