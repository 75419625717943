<template>
  <header class="header-background">
    <nav>
      <img
        src="../../assets/logo.png"
        width="40"
        height="40"
        alt="The website logo which is a curved letter W with a snake head on the upper left"
        @click="navigateToHomePage()"
      >
      <ul class="navigation-desktop">
        <li>
          <router-link to="/my-library">
            Library
          </router-link>
        </li>
        <li>
          <router-link to="/add-word">
            Add
          </router-link>
        </li>
        <li>
          <router-link to="/import-words">
            Import
          </router-link>
        </li>
        <span
          v-if="!userAuthenticated"
          class="actions"
        >
          <li class="separator">|</li>
          <li>
            <router-link to="/sign-up">
              Sign up
            </router-link>
          </li>
          <li>
            <router-link 
              to="/log-in"
            >
              Log in
            </router-link>
          </li>
        </span>
        <span
          v-if="userAuthenticated"
          class="actions"
        >
          <li class="separator">|</li>
          <li>
            <router-link 
              to="/account"
            >
              Account
            </router-link>
          </li>
          <li>
            <a @click="logOut">Log out</a>
          </li>
        </span>
      </ul>
      <button
        class="open-navigation-mobile"
        @click="openMobileNavigation()"
      >
        &#9776;
      </button>
    </nav>
  </header>

  <div
    id="sidePanel"
    class="sidepanel header-background"
  >
    <a
      href="javascript:void(0)"
      class="close-navigation-mobile"
      @click="closeMobileNavigation()"
    >&times;
    </a>
    <ul class="navigation-mobile">
      <li class="navigation-mobile-link">
        <router-link
          to="/my-library"
        >
          Library
        </router-link>
      </li>
      <li class="navigation-mobile-link">
        <router-link
          to="/add-word"
        >
          Add
        </router-link>
      </li>
      <li class="navigation-mobile-link">
        <router-link
          to="/import-words"
        >
          Import
        </router-link>
      </li>
      <span v-if="!userAuthenticated">
        <li class="separator">&#8212;</li>
        <li class="navigation-mobile-link">
          <router-link to="/sign-up">
            Sign up
          </router-link>
        </li>
        <li class="navigation-mobile-link">
          <router-link 
            to="/log-in"
          >
            Log in
          </router-link>
        </li>
      </span>
      <span v-if="userAuthenticated">
        <li class="separator">&#8212;</li>
        <li class="navigation-mobile-link">
          <router-link 
            to="/account"
          >
            Account
          </router-link>
        </li>
        <li class="navigation-mobile-link">
          <a @click="logOut">Log out</a>
        </li>
      </span>
    </ul>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import closeSidePanel from "../../util/side-panel-utils.js";

export default {
  computed: {
    userAuthenticated() {
      return this.$store.getters.userAuthenticated;
    },
  },
  methods: {
    navigateToHomePage() {
      this.$router.push("/home");
    },

    /* Set the width of the sidebar to show it */
    openMobileNavigation() {
      document.getElementById("sidePanel").style.width = "100%";
    },

    /* Set the width of the sidebar to hide it */
    closeMobileNavigation() {
      closeSidePanel();
    },

    async logOut() {
      try {
        await Auth.signOut();
        this.$store.dispatch('demoUserHasConnected');
        this.$router.push('/home');
      } catch (error) {
          console.log('error signing out: ', error);
      }
    },
  },
};
</script>

<style scoped>
.header-background {
  background-color: black;
}

header {
  height: 4rem;
  display: flex;
}

header nav {
  width: 90%;
  max-width: 60rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.navigation-desktop .actions {
   display: flex;
}

img {
  cursor: pointer;
}

.navigation-desktop {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-desktop li {
  margin: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

a {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.open-navigation-mobile:hover,
a:hover,
a.router-link-active {
  color: #1db954;
}

ul .separator {
  color: white;
  cursor: default;
}

.navigation-desktop {
  display: none;
}

/* The sidepanel menu */
.sidepanel {
  height: 100%; /* Specify a height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 3.7rem; /* Place content X from the top */
  transition: 0.2s; /* transition effect to slide in the sidepanel */
  font-weight: bold;
}

/* Position and style the close button (top right corner) */
.sidepanel .close-navigation-mobile {
  position: absolute;
  top: 0.3rem;
  right: 1.1rem;
  font-size: 2.8rem;
}

.sidepanel .navigation-mobile {
  line-height: 2.5;
  list-style: none;
}

.sidepanel .navigation-mobile-link {
  font-size: 1.55rem;
}

/* Style the button that is used to open the sidepanel */
.open-navigation-mobile {
  font-size: 1.9rem;
  font-weight: bold;
  background-color: transparent;
  color: white;
  padding: 0rem 0rem;
  border: none;
}

@media only screen and (min-width: 768px) {
  .navigation-desktop {
    display: flex;
  }

  .open-navigation-mobile {
    display: none;
  }
}
</style>



