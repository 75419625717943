<template>
  <base-dialog
    :show="!userAuthenticated && !helpInstructionsHasBeenShowed"
    title="Help: My Library"
    @close="closeHelpPopup"
  >
    <p>
      Your character library contains 10 Chinese characters / Japanese Kanji that you encountered at least 3
      times during your language study. These characters are very important because they will
      significantly help you understand the topics <strong>that interest you</strong>. To learn them, we
      recommend that you use your favorite flashcard tool.
    </p>
  </base-dialog>
  <TheDemoBanner v-if="!userAuthenticated" />
  <base-container>
    <template #title>
      Useful Characters
    </template>
    <template #default>
      <span v-if="usefulWordsEmpty">
        No useful characters found in your library.
      </span>
      <span v-else>
        <table>
          <tr>
            <th>Word</th>
            <th>Frequency</th>
            <th />
          </tr>
          <tbody>
            <tr
              v-for="word in usefulWords"
              :key="word.sk.S"
            >
              <td>{{ word.sk.S }}</td>
              <td>{{ word.frequency.N }}</td>
              <td><a @click="deleteWord(word.sk.S)">delete</a></td>
            </tr>
          </tbody>
        </table>
      </span>
    </template>
  </base-container>
</template>

<script>
import TheDemoBanner from "../components/TheDemoBanner.vue";
import closeSidePanel from "../util/side-panel-utils.js";

export default {
  components: {
    TheDemoBanner,
  },
  data() {
    return {
      helpInstructionsHasBeenShowed: false,
      usefulWords: null,
    };
  },
  computed: {
    userAuthenticated() {
      return this.$store.getters.userAuthenticated;
    },
    usefulWordsEmpty() {
      return this.usefulWords === null || this.usefulWords.length == 0;
    },
  },
  beforeMount() {
    this.usefulWords = this.$store.getters.wordsClient
      .findUsefulWords()
      .then((usefulWords) => {
        this.usefulWords = usefulWords;
      })
      .catch((error) => {
        console.log(error.message || 'Load words failure');
      })
      .finally(() => {
        closeSidePanel();
      }); 
  },
  mounted() {
    this.$gtag.event("page_view", {
      page_path: "/my-library",
    });
  },
  methods: {
    deleteWord(word) {
      this.$store.getters.wordsClient
        .delete(word)
        .then(() => {
          return this.$store.getters.wordsClient.findUsefulWords();
        })
        .then((usefulWords) => {
          this.usefulWords = usefulWords;
        })
        .catch((error) => {
          console.log(error.message || 'Delete or load words failure');
        }); 
    },
    closeHelpPopup() {
      this.helpInstructionsHasBeenShowed = true;
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 45rem;
}

table {
  width: 100%;
  border-spacing: 0;
}

th {
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #c1c3c6;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 1rem;
}

tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

td {
  padding: 1rem;
  text-align: center;
}

a {
  font-size: 0.8rem;
  color: #1db954;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
</style>