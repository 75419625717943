<template>
  <div class="section">
    <base-dialog
      :show="!userAuthenticated && !helpInstructionsHasBeenShowed"
      title="Help: Import Words"
      @close="closeHelpPopup"
    >
      <p>
        Let's import your Chinese words (traditional form) from Pleco©.
        <ol>
          <li>In Pleco, follow the instructions "Import / Export" under "Bookmarks" to export an <b>XML</b> file.</li>
          <li>Back to snapvocab, select the file exported in the previous step to start importing words inside it.</li>
        </ol>
      </p>
    </base-dialog>
    <base-dialog
      :show="importCompleted"
      title="Import Status"
      @close="closeImportPopup"
    >
      <p>
        {{ importStatus }}
      </p>
    </base-dialog>

    <TheDemoBanner v-if="!userAuthenticated" />
    <base-container>
      <template #title>
        Import Words From Pleco
      </template>
      <template #default>
        <form @submit.prevent="submitFile">
          <span>
            <input
              id="file"
              ref="file"
              type="file"
              accept=".xml" 
              class="inputfile"
              required
              @change="uploadFile"
            >
            <label for="file">{{ label }}</label>
          </span>
          <span>
            <button>
              Import
            </button>
          </span>
        </form>
      </template>
    </base-container>
  </div>
</template>

<script>
import TheDemoBanner from "../components/TheDemoBanner.vue";
import closeSidePanel from "../util/side-panel-utils.js";
import parseBookmarks from "../api/pleco-parser-client.js";

const DEFAULT_LABEL = "Choose a bookmarks file...";

export default {
  components: {
    TheDemoBanner,
  },
  data() {
    return {
      helpInstructionsHasBeenShowed: false,
      importCompleted: false,
      file: null,
      label: DEFAULT_LABEL,
      importStatus: null,
    };
  },
  computed: {
    userAuthenticated() {
      return this.$store.getters.userAuthenticated;
    },
  },
  beforeMount() {
    closeSidePanel();
  },
  mounted() {
    this.$gtag.event("page_view", {
      page_path: "/import-words",
    });

    this.$refs.file.addEventListener('change', (e) => {
      let selectedFileName = e.target.value.split('\\').pop();
      this.label = selectedFileName;
    });
  },
  methods: {
    uploadFile() {
      this.file = this.$refs.file.files[0];
    },
    submitFile(event) {
      if (this.file) {
        parseBookmarks(this.file, this.onParsingComplete);
      }
      this.resetForm(event);  
    },
    onParsingComplete(words) {
      if(words == null) {
        this.importStatus = "The file is not a valid Pleco (Version 2) XML file. Please follow the import instructions and try again.";
        this.importCompleted = true;
      } else {
        this.$store.getters.wordsClient.save(words);
        this.importStatus = "Congratulations! " + words.length + " words have been found in the bookmarks. The import has started and may take some time. Thanks for your patience.";
        this.importCompleted = true;
      }
    },
    resetForm(event) {
      event.target.reset();
      this.file = null;
      this.label = DEFAULT_LABEL;
    },
    closeHelpPopup() {
      this.helpInstructionsHasBeenShowed = true;
    },
    closeImportPopup() {
      this.importCompleted = false;
      this.importStatus = '';
    },
  },
};
</script>

<style scoped>
.section {
  height: 100vh;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-size: 1rem;
    display: inline-block;
    cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label,
.inputfile + label:hover {
    color: #1db954;
}

/* https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */
.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

button {
  background-color: #1ed760;
  border: 0px solid transparent;
  border-color: #116e32;
  border-radius: 500px;
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 0.6rem 2rem;
  text-transform: uppercase;
}

span {
  margin: auto 0.5rem;
}

</style>