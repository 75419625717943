<template>
  <TheHeader />
  <router-view />
  <TheFooter />
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";

export default {
  name: "Main",
  components: {
    TheHeader,
    TheFooter,
  },
  created() {
    try {
      this.$store.dispatch("demoUserHasConnected");
    } catch (error) {
      console.log(error.message || 'demo user initialization failure');
    }
  }
};
</script>

<style>
* {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, sans-serif;
}

body {
  margin: 0;
  background: #f8f8f8;
}

section {
  width: 90%;
  max-width: 60rem;
  margin: auto;
}
</style>
