const FIND_BOOKMARKS_VERSION_EXPRESSION = "/plecoflash[@formatversion='2']";
const FIND_TRADITIONAL_WORD_EXPRESSION= "/plecoflash/cards/card/entry/headword[@charset='tc']";

export default function parseBookmarks(file, callback) { 
  let url = URL.createObjectURL(file);
  let xhttp = _makeXMLHttpRequest(callback);
  xhttp.open("GET", url, true);
  xhttp.send(); 
}

function _makeXMLHttpRequest(callback) {
  let xhttp = new XMLHttpRequest();

  xhttp.addEventListener('readystatechange', () => {  
    if (xhttp.readyState == xhttp.DONE && xhttp.status == 200) {
      let xml = xhttp.responseXML;
      if(_isBookmarksVersion2(xml)) {
        const words = _readWords(xml);
        callback(words);
      } else {
        callback(null);
      }
    }
  });

  return xhttp;
}

function _isBookmarksVersion2(xml) {    
  var plecoFlashNode = xml.evaluate(FIND_BOOKMARKS_VERSION_EXPRESSION, xml, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
  return plecoFlashNode.singleNodeValue;
}

function _readWords(xml) {
  const words = [];
  
  let nodes = xml.evaluate(FIND_TRADITIONAL_WORD_EXPRESSION, xml, null, XPathResult.ANY_TYPE, null);
  let result = nodes.iterateNext();
  while (result) {
    words.push(result.childNodes[0].nodeValue);
    result = nodes.iterateNext();
  }
  
  return words;
}
