import { SnapvocabStatefulStack } from '../../cdk-exports.json';
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";

function getDynamoClientForAuthenticatedUser(jwtToken) {
  let loginCognitoToken = [];
  loginCognitoToken['cognito-idp.' + SnapvocabStatefulStack.awscognitoregion + '.amazonaws.com/' + SnapvocabStatefulStack.awsuserpoolsid] = jwtToken;

  return new DynamoDBClient({
    region: SnapvocabStatefulStack.awsprojectregion,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: SnapvocabStatefulStack.awscognitoregion }),
      identityPoolId: SnapvocabStatefulStack.awscognitoidentitypoolid,
      logins: {
        ...loginCognitoToken
      }
    }),
  });
}

function getDynamoClientForUnauthenticatedUser() {
  return new DynamoDBClient({
    region: SnapvocabStatefulStack.awsprojectregion,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: SnapvocabStatefulStack.awscognitoregion }),
      identityPoolId: SnapvocabStatefulStack.awscognitoidentitypoolid
    }),
  });
}

export { getDynamoClientForAuthenticatedUser, getDynamoClientForUnauthenticatedUser};