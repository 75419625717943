import InMemoryWordsClient from "../api/in-memory-words-client.js";
import DynamoWordsClient from "../api/dynamo-words-client.js";
import CustomersClient from "../api/customers-api-client.js";

export default {
  demoUserHasConnected(context) {
    context.commit("initStateForDemoUser", {
      wordsClient: new InMemoryWordsClient(),
      customersClient: new CustomersClient(),
    });
  },
  
  userHasSignedIn(context, user) {
    context.commit("initStateForSignedInUser", {
      cognitoUser: user, 
      wordsClient: new DynamoWordsClient(user),
      customersClient: new CustomersClient(user),
    });
  },
};
