import { SnapvocabStatefulStack } from '../../cdk-exports.json';
import { WordsClient, MAX_WORDS_RETURNED, USEFUL_WORD_MIN_FREQUENCY, createNewWordItemWith, incrementWordFrequency } from './words-client.js'
import { QueryCommand, BatchWriteItemCommand, DeleteItemCommand, BatchGetItemCommand } from "@aws-sdk/client-dynamodb";
import { getDynamoClientForAuthenticatedUser } from "./dynamo-client.js";

const BATCH_GET_ITEM_MAX_ITEMS = 100;
const BATCH_WRITE_ITEM_MAX_ITEMS = 25;

export default class DynamoWordsClient extends WordsClient {
  constructor(user) {
    super();
    this._user = user;
  }

  async findUsefulWords() {
    return this._user.getSession( (err, userSession) => {
      if (err) {
        throw Error(err);
      } else {
        return this._findUsefulWords(userSession);
      }
    });
  }

  async _findUsefulWords(userSession) {
    const dynamoClient = getDynamoClientForAuthenticatedUser(userSession.getIdToken().getJwtToken());

    const params = {
      TableName: SnapvocabStatefulStack.wordstablename,
      IndexName: SnapvocabStatefulStack.wordsfrequencywordindexname,
      KeyConditionExpression: 'pk = :pk AND frequencyword >= :sk',
      ExpressionAttributeValues: {
          ":pk": { S: userSession.getIdToken().payload.sub },
          ":sk": { S: '' + USEFUL_WORD_MIN_FREQUENCY },
      },
      ScanIndexForward: false,
      Limit: MAX_WORDS_RETURNED,
    };
    
    const command = new QueryCommand(params);

    const result = await dynamoClient.send(command);
    
    return result.Items;
  }

  async delete(word) {
    await this._user.getSession( async (err, userSession) => {
      if (err) {
        throw Error(err);
      } else {
        await this._deleteWordFromLibrary(userSession, word);
      }
    });
  }

  async _deleteWordFromLibrary(userSession, word) {
    const dynamoClient = getDynamoClientForAuthenticatedUser(userSession.getIdToken().getJwtToken());

    const params = {
      TableName: SnapvocabStatefulStack.wordstablename,
      Key: {
        pk: { S: userSession.getIdToken().payload.sub },
        sk: { S: word }
      },
    };

    const command = new DeleteItemCommand(params);
    await dynamoClient.send(command);
  }

  async save(words) {
    const wordsAsLowerCase = words.map(element => element.toLowerCase());
    this._user.getSession( (err, userSession) => {
      if (err) {
        throw Error(err);
      } else {
        this._addWordsToLibrary(userSession, wordsAsLowerCase);
      }
    });
  }

  async _addWordsToLibrary(userSession, words) {
    const existingWordsAsItem = await this._findExistingWords(userSession, words);
    const existingWordsIncrementedAsItem = existingWordsAsItem.map(fw => {
      return incrementWordFrequency(fw);
    });

    const newWords = this._extractNewWordsFrom(words, existingWordsAsItem);
    const newWordsAsItem = [];
    newWords.forEach(word => {
      newWordsAsItem.push(createNewWordItemWith(userSession.getIdToken().payload.sub, word));
    });

    await this._persistInBatch(userSession, newWordsAsItem.concat(existingWordsIncrementedAsItem));
    console.log("Words has been added to the library");
  }

  async _findExistingWords(userSession, words) {
    let existingWordsAsItem = [];

    let readIndex = 0;
    while(readIndex < words.length) {
      let nbEltToRead = Math.min(words.length - readIndex, BATCH_GET_ITEM_MAX_ITEMS);
      const foundWords = await this._findWordsBy(userSession, words.slice(readIndex, readIndex + nbEltToRead));
      existingWordsAsItem = existingWordsAsItem.concat(foundWords);
      readIndex += nbEltToRead;
    }

    return existingWordsAsItem
  }

  async _findWordsBy(userSession, words) {
    // To prevent ProvisionedThroughputExceededException
    await new Promise(resolve => setTimeout(resolve, 3000));

    const keys = [];
    words.forEach(word => {
      keys.push({
        pk: { S: userSession.getIdToken().payload.sub },
        sk: { S: word }
      });
    });
    const command = new BatchGetItemCommand({
      RequestItems: {
        [SnapvocabStatefulStack.wordstablename]: {
          Keys: keys,
        },
      },
    });
    
    const dynamoClient = getDynamoClientForAuthenticatedUser(userSession.getIdToken().getJwtToken());
    const result = await dynamoClient.send(command);
    if (!result.Responses) {
      throw new Error("result.Responses is undefined from BatchGetItemCommand");
    }

    return result.Responses[SnapvocabStatefulStack.wordstablename];
  }

  _extractNewWordsFrom(words, existingWordsAsItem) {
    return words.filter(function(value) {
      for(let i = 0; i < existingWordsAsItem.length; i++){ 
        if(existingWordsAsItem[i].sk.S === value) {
          return false;
        }
      }
      return true;
    });
  }

  async _persistInBatch(userSession, wordsItem) {
    let readIndex = 0;
    while(readIndex < wordsItem.length) {
      let nbEltToRead = Math.min(wordsItem.length - readIndex, BATCH_WRITE_ITEM_MAX_ITEMS);
      await this._persist(userSession, wordsItem.slice(readIndex, readIndex + nbEltToRead));
      readIndex += nbEltToRead;
    }
  }

  async _persist(userSession, wordsItem) {
    // To prevent ProvisionedThroughputExceededException
    await new Promise(resolve => setTimeout(resolve, 5000));

    const putRequests = [];
    wordsItem.forEach(w => {
      putRequests.push({
        PutRequest: {
          Item: {
            ...w
          },
        },
      });
    });

    const command = new BatchWriteItemCommand({
      RequestItems: {
        [SnapvocabStatefulStack.wordstablename]: putRequests
      },
    });
    
    const dynamoClient = getDynamoClientForAuthenticatedUser(userSession.getIdToken().getJwtToken());
    dynamoClient.send(command);
  }
}
